//=================================================
// Header
//=================================================

header {
    > .wrapper {
        padding-top: $header_padding_t;
        padding-bottom: $header_padding_b;
        
        @media #{$media_sm} {
            .menu-toggle {
                .icon-menu {
                    display: none;
                }
            }
            
            .w-75 {
                width: 100% !important;
            }
        }
    }
}
