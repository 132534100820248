//=================================================
// Alert
//=================================================

[class*='alert-dismiss'] {
    position: absolute;
    right: 14px;
    top: 10px;
    padding: 3px;
    font-size: 20px;
    line-height: 1;
    background: transparent;
    color: inherit;
    border: none;
    cursor: pointer;
}

// Contact form
[class*='response-output'] {
    @extend .alert;
    
    display: block !important;
    position: absolute;
    border: 1px solid transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

[class*='validation-errors'], 
[class*='acceptance-missing'] {
    @extend .alert-danger;
    
    border-color: #f7d4d7 !important;
}

[class*='mail-sent-ok'] {
    @extend .alert-success;
    
    border-color: #dbf2e0 !important;
}

[class*='validation-errors'],
[class*='acceptance-missing'],
[class*='mail-sent-ok'] {
    padding: .75rem 1.25rem !important;
    margin-top: $space_sm !important;
}